








































































































































































































































































.el-dialog {
  width: 50%;
}
@media (max-width: 800px) {
  .el-dialog {
    width: 90%;
  }
}
